"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("ayvri-ui/lib/styles");
var styles_2 = require("ayvri-ui/lib/styles");
exports.hideOnMobile = styles_2.hideOnMobile;
exports.forMobile = styles_2.forMobile;
exports.forDesktop = styles_2.forDesktop;
var GAP_VALUE = 1.875;
exports.kGap = GAP_VALUE + "rem";
exports.kHalfGap = GAP_VALUE / 2 + "rem";
exports.kQuarterGap = GAP_VALUE / 4 + "rem";
exports.kVertSpace = '0.5rem';
exports.kMidBase = '2.5rem';
exports.kBase = '4rem';
exports.kBorderRadius = '2';
exports.generateAnchorStyles = function (color) { return ({
    color: styles_1.getHex(color),
    textDecoration: 'none',
    $nest: {
        '&:active, &:visited, &:hover, &:link': {
            color: styles_1.getHex(color),
            textDecoration: 'none',
        },
    },
}); };
exports.gridUnits = function (count) { return count * 4 + "rem"; };
