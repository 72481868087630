"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var fn_1 = require("ayvri-std/lib/fn");
var button_1 = require("ayvri-ui/lib/button");
var search_input_1 = require("ayvri-ui/lib/search-input");
var usermenu_1 = require("ayvri-ui/lib/usermenu");
var React = require("react");
var react_1 = require("react");
var typestyle_1 = require("typestyle");
var env_1 = require("../../helpers/env");
var layouts_1 = require("../../styles/layouts");
var state_1 = require("./state");
var styles_1 = require("./styles");
// a matchMedia compatible device query
var kMobileDeviceQuery = '(max-width: 900px)';
var kIsBrowser = typeof matchMedia !== 'undefined';
var kInitialMode = kIsBrowser && matchMedia(kMobileDeviceQuery).matches ? 'mobile' : 'desktop';
exports.Header = function (props) {
    var staticSrcPath = props.staticSrcPath, loginState = props.loginState, initialSearchText = props.initialSearchText, onSearchSubmit = props.onSearchSubmit;
    var _a = react_1.useReducer(state_1.reducer, {
        displayMode: kInitialMode,
    }), _b = _a[0], displayMode = _b.displayMode, searchCollapsed = _b.searchCollapsed, dispatch = _a[1];
    react_1.useEffect(function () {
        var isMobile = matchMedia(kMobileDeviceQuery);
        // change monitoring not supported on edge, so disable
        if (typeof isMobile.addEventListener === 'function') {
            isMobile.addEventListener('change', function () {
                dispatch(state_1.toggleDisplayMode(isMobile.matches ? 'mobile' : 'desktop'));
            });
        }
    }, []);
    return (React.createElement("div", { className: styles_1.styles.wrapper },
        React.createElement("div", { className: typestyle_1.classes(layouts_1.centerColumn, styles_1.styles.container) },
            React.createElement("div", { className: styles_1.styles.section },
                React.createElement("a", { href: '/' },
                    React.createElement("img", { className: styles_1.styles.logo, src: staticSrcPath + "/assets/images/logos/ayvri-logo-color.svg" })))),
        React.createElement("div", { className: styles_1.styles.annoucement },
            "We're sorry to say Ayvri will be shutting down.",
            React.createElement("a", { href: '/pages/about' }, " Find out more..."))));
    function renderSearch() {
        if (!kIsBrowser) {
            return null;
        }
        return (React.createElement(search_input_1.SearchInput, { placeholder: 'explore', mode: displayMode, collapsed: fn_1.def(displayMode === 'mobile', searchCollapsed), initialText: initialSearchText, onToggleCollapsed: function (collapsed) { return dispatch(state_1.toggleSearchCollapsed(collapsed)); }, onSubmit: fn_1.def(redirectToExplorePage, onSearchSubmit) }));
    }
    function renderUserMenu() {
        if (loginState.type !== 'logged_in') {
            return React.createElement("a", { href: loginState.loginUrl }, "Sign In");
        }
        var user = __assign(__assign({}, loginState.user), { avatarUrl: fn_1.def(env_1.env().staticSrcPath() + "/assets/images/logos/_a_transparent.svg", loginState.user.avatarUrl) });
        return (React.createElement("div", { className: styles_1.styles.userMenu },
            React.createElement(usermenu_1.UserMenu, __assign({}, user, { arrowUrl: staticSrcPath + "/assets/images/icons/arrow-down.svg", profileUrl: "/profile/" + loginState.user.handle }),
                React.createElement("a", { href: "/profile/" + loginState.user.handle }, "Profile"),
                React.createElement("a", { href: '/settings' }, "Settings"),
                React.createElement("a", { href: '/settings/pro' }, "PRO Account"),
                React.createElement("a", { href: '/logout' }, "Logout"))));
    }
    function redirectToExplorePage(searchText) {
        window.location.replace("/explore?q=" + encodeURIComponent(searchText));
    }
};
exports.TallHeader = function (props) {
    var title = props.title, showButton = props.showButton, onButtonClick = props.onButtonClick;
    return (React.createElement("div", { className: styles_1.styles.tallHeader() },
        React.createElement("div", { className: layouts_1.centerColumn + " " + styles_1.styles.tallHeaderContent() },
            React.createElement("img", { src: env_1.env().staticSrcPath() + '/assets/images/logos/_a.svg' }),
            React.createElement("div", { className: styles_1.styles.pageTitle() }, title),
            showButton ? (React.createElement(button_1.Button, { type: 'primary', size: 'large', onClick: onButtonClick }, "Continue")) : (React.createElement("div", null)),
            React.createElement("a", { href: '/' },
                React.createElement("img", { src: env_1.env().staticSrcPath() + '/assets/images/icons/close_color.svg' })))));
};
exports.Header.displayName = 'Header';
