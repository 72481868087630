"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var typestyle_1 = require("typestyle");
var env_1 = require("../../helpers/env");
var layouts_1 = require("../../styles/layouts");
var styles_1 = require("./styles");
exports.Footer = function () { return (React.createElement("div", { className: styles_1.styles.wrapFooter },
    React.createElement("div", { className: typestyle_1.classes(layouts_1.boxLayout, styles_1.styles.footer) },
        React.createElement("div", null,
            React.createElement("h2", null, "Company"),
            React.createElement("a", { href: '/pages/about' }, "About"),
            React.createElement("a", { href: 'https://blog.ayvri.com', target: '_new' }, "Blog"),
            React.createElement("a", { href: 'mailto:hello@ayvri.com' }, "Contact")),
        React.createElement("div", null,
            React.createElement("h2", null, "Stay Connected"),
            React.createElement("div", { className: styles_1.styles.socialLinks },
                React.createElement("a", { href: 'https://facebook.com/ayvri.world', target: '_new' },
                    React.createElement("img", { src: env_1.env().staticSrcPath() + "/assets/images/icons/facebook_color.svg" })),
                React.createElement("a", { href: 'https://twitter.com/_ayvri', target: '_new' },
                    React.createElement("img", { src: env_1.env().staticSrcPath() + "/assets/images/icons/twitter_color.svg" }))))),
    React.createElement("div", { className: styles_1.styles.pageCap },
        React.createElement("img", { src: env_1.env().staticSrcPath() + "/assets/images/logos/aa_grey.svg" }),
        React.createElement("div", null,
            React.createElement("a", { href: '/pages/terms' }, "Terms of Use"),
            React.createElement("span", null,
                "\u00A9 ayvri ",
                new Date().getFullYear()),
            React.createElement("a", { href: '/pages/privacy' }, "Privacy Policy"),
            React.createElement("a", { 
                // tslint:disable-next-line:max-line-length
                href: 'http://appft.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&p=1&u=/netahtml/PTO/srchnum.html&r=1&f=G&l=50&d=PG01&s1=20160148418.PGNR.', target: '_new' }, "Patent"))))); };
