"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var typestyle_1 = require("typestyle");
var _1 = require("./");
exports.centerColumn = typestyle_1.style({
    width: '80vw',
    maxWidth: '72rem',
    marginLeft: 'auto',
    marginRight: 'auto',
}, _1.forMobile({
    maxWidth: 'calc(100vw - 2rem)',
    marginLeft: '1rem',
    marginRight: '1rem',
    width: '100%',
}));
exports.twoColumn = typestyle_1.style(__assign({ display: ' grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: _1.kGap, marginTop: _1.kBase }, _1.forMobile({
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr',
})));
// deprecated - use centerColumn and apply the flex style if required
// NOTE: this only exists here to aid conversion of any pages that are using
// the SASS template and need to move over with minimal effort
exports.boxLayout = typestyle_1.style({
    display: 'flex',
    flexDirection: 'column',
    width: '80vw',
    maxWidth: '72rem',
    marginLeft: 'auto',
    marginRight: 'auto',
}, _1.forMobile({
    maxWidth: 'calc(100vw - 2rem)',
    marginLeft: '1rem',
    marginRight: '1rem',
    width: '100%',
}));
exports.hideOnMobile = typestyle_1.style(__assign({}, _1.forMobile({
    display: 'none',
})));
