"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_dom_1 = require("react-dom");
var typestyle_1 = require("typestyle");
exports.hydrateToRoot = function (element) {
    var output = react_dom_1.hydrate(element, document.getElementById('root'));
    var styleTag = document.getElementById('typestyles');
    if (styleTag) {
        typestyle_1.setStylesTarget(styleTag);
    }
    return output;
};
