"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleDisplayMode = function (mode) { return ({
    type: 'toggle_display_mode',
    mode: mode,
}); };
exports.toggleSearchCollapsed = function (collapsed) { return ({
    type: 'toggle_search_collapsed',
    collapsed: collapsed,
}); };
exports.reducer = function (state, action) {
    switch (action.type) {
        case 'toggle_display_mode':
            return __assign(__assign({}, state), { displayMode: action.mode });
        case 'toggle_search_collapsed':
            return __assign(__assign({}, state), { searchCollapsed: action.collapsed });
    }
};
