"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("ayvri-ui/lib/styles");
var typestyle_1 = require("typestyle");
var styles_2 = require("../../styles");
var wrapper = typestyle_1.style({
    background: styles_1.getHex('brand2'),
    color: styles_1.getHex('white'),
});
var container = typestyle_1.style({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '4rem',
    width: 'auto',
}, styles_2.forMobile({
    padding: '1rem 0',
    marginLeft: 0,
}));
var logo = typestyle_1.style({
    marginRight: '2.5vw',
    paddingLeft: styles_2.kHalfGap,
    paddingTop: '5px',
}, styles_2.forMobile({
    height: '1.5rem',
    paddingLeft: '0.35rem',
    marginLeft: '0.5rem',
}));
var searchContainer = typestyle_1.style({
    width: '200px',
}, styles_2.forMobile({
    minWidth: '32px',
    width: 'auto',
}));
var createYourOwn = typestyle_1.style(__assign({ display: 'flex', alignItems: 'center', background: styles_1.getHex('brand1'), fontWeight: 'bold', fontSize: '0.75rem', height: '2rem', padding: '0.25rem 1rem', borderRadius: '3rem', border: '1px solid transparent' }, styles_2.generateAnchorStyles('textlight')), styles_2.forDesktop({
    $nest: {
        '& > img': {
            display: 'none',
        },
        '&:hover': __assign({ background: styles_1.getHex('textlight'), color: styles_1.getHex('brand2') }, styles_2.generateAnchorStyles('brand2')),
    },
}), styles_2.forMobile({
    background: 'inherit',
    padding: 0,
    $nest: {
        '& > span': {
            display: 'none',
        },
    },
}));
var section = typestyle_1.style({
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: styles_2.kGap,
    alignItems: 'center',
});
var linksSection = typestyle_1.style({
    $nest: {
        '& > a': __assign(__assign({}, styles_2.generateAnchorStyles('textlight')), { fontWeight: 700 }),
    },
});
var headerLink = typestyle_1.style(styles_2.forMobile({
    display: 'none',
}));
var userMenu = typestyle_1.style({
    display: 'flex',
}, styles_2.forMobile({
    marginRight: "-" + styles_2.kGap,
}));
var annoucement = typestyle_1.style({
    fontSize: styles_1.getFontSize('title2'),
    backgroundColor: styles_1.getHex('brand1'),
    textAlign: 'center',
});
var tallHeader = function () {
    return typestyle_1.style({
        backgroundColor: styles_1.getHex('brand2'),
        height: '9.5rem',
        $nest: {
            img: {
                height: styles_2.kMidBase,
            },
        },
    });
};
var tallHeaderContent = function () {
    return typestyle_1.style({
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '40px 3fr 1fr 40px',
        gridColumnGap: styles_2.kHalfGap,
        alignItems: 'center',
    }, styles_2.forMobile({
        gridTemplateColumns: '40px 1fr 100px 40px',
    }));
};
var pageTitle = function () {
    return typestyle_1.style({
        fontSize: styles_1.getFontSize('display'),
        color: styles_1.getHex('white'),
        marginLeft: styles_2.kHalfGap,
    }, styles_2.forMobile({
        fontSize: styles_1.getFontSize('body'),
    }));
};
exports.styles = {
    wrapper: wrapper,
    container: container,
    logo: logo,
    searchContainer: searchContainer,
    createYourOwn: createYourOwn,
    section: section,
    linksSection: linksSection,
    headerLink: headerLink,
    userMenu: userMenu,
    tallHeader: tallHeader,
    tallHeaderContent: tallHeaderContent,
    pageTitle: pageTitle,
    annoucement: annoucement,
};
