"use strict";
// such isomorphic.
Object.defineProperty(exports, "__esModule", { value: true });
function setLoginUrl(url) {
    return {
        url: url,
        type: 'SET_LOGIN_URL',
    };
}
exports.setLoginUrl = setLoginUrl;
function loginUrlReducer(state, action) {
    if (state === void 0) { state = exports.defaultLoginUrl; }
    switch (action.type) {
        case 'SET_LOGIN_URL':
            return { loginUrl: action.url || '' }; // TODO: is this right, or is there a default url?
        default:
            return state;
    }
}
exports.loginUrlReducer = loginUrlReducer;
exports.defaultLoginUrl = { loginUrl: '' };
