"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
// tslint:disable-next-line:no-var-requires
var styles = require('./hero.css');
var iframe = function (videoId) {
    // tslint:disable-next-line:max-line-length
    return "<iframe src=\"https://www.youtube.com/embed/" + videoId + "?modestbranding=1&autoplay=1&mute=1&loop=1&playlist=" + videoId + "&controls=0&showinfo=0&wmode=transparent&branding=0&rel=0&autohide=0&enablejsapi=1&origin=http%3A%2F%2Fayvri.com&widgetid=1\"\n    frameBorder=\"0\"\n    allow=\"autoplay; encrypted-media;\"\n    ></iframe>";
};
exports.Hero = function (props) {
    return (React.createElement("div", { className: styles.hero, style: { backgroundImage: "url(" + props.backgroundImg + ")" } },
        React.createElement("div", { className: styles.letterBox, dangerouslySetInnerHTML: { __html: iframe(props.videoId) } }),
        props.children));
};
