"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("ayvri-std/lib/polyfill");
require("whatwg-fetch");
var fn_1 = require("ayvri-std/lib/fn");
var redux_1 = require("redux");
var rendering_1 = require("../../pages/rendering");
var login_1 = require("../../reducers/login");
var user_1 = require("../../reducers/user");
var index_1 = require("./index");
var preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
delete preloadedState.___;
var store = redux_1.createStore(redux_1.combineReducers({ user: user_1.user, loginUrl: login_1.loginUrlReducer }), preloadedState);
var loginState = preloadedState.loginState;
var deserializedFeatures = fn_1.def({}, window.features);
rendering_1.hydrateToRoot(index_1.homeJSX(store, loginState, deserializedFeatures));
