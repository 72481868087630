"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("ayvri-ui/lib/styles");
var typestyle_1 = require("typestyle");
var styles_2 = require("../../styles");
var styles_3 = require("../../styles");
var wrapFooter = typestyle_1.style({
    backgroundColor: styles_1.getHex('brand2'),
});
var footer = typestyle_1.style({
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: styles_3.kGap + " 0",
    color: styles_1.getHex('white'),
    $nest: {
        '& h2': {
            color: styles_1.getHex('grey2'),
        },
        '& a': __assign(__assign({}, styles_3.generateAnchorStyles('textlight')), { display: 'block', fontSize: styles_1.getFontSize('title2') }),
    },
}, styles_2.forMobile({
    flexDirection: 'column',
    marginLeft: styles_3.kGap,
    $nest: {
        '& h2': {
            marginTop: styles_3.kGap,
        },
    },
}));
var socialLinks = typestyle_1.style({
    display: 'flex',
    $nest: {
        '& > a': {
            paddingRight: styles_3.kHalfGap,
        },
    },
});
var pageCap = typestyle_1.style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: styles_3.kGap + " 0",
    flexDirection: 'column',
    $nest: {
        '& > div': {
            color: styles_1.getHex('brand3'),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: styles_3.gridUnits(6),
            fontWeight: 700,
            padding: styles_3.kGap,
            $nest: {
                '& > *': {
                    padding: '0 0.25rem',
                },
                '& a': __assign({}, styles_3.generateAnchorStyles('brand3')),
            },
        },
    },
}, styles_2.forMobile({
    $nest: {
        '& > div': {
            width: 'auto',
            padding: 0,
            $nest: {
                '& a': {
                    padding: '0.5rem',
                },
            },
        },
    },
}));
exports.styles = {
    wrapFooter: wrapFooter,
    footer: footer,
    socialLinks: socialLinks,
    pageCap: pageCap,
};
