"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fn_1 = require("ayvri-std/lib/fn");
var preconditions_ts_1 = require("preconditions-ts");
function fromEnv(key, customError) {
    // tslint:disable-next-line:no-process-env
    return preconditions_ts_1.required(process.env[key], customError || key + " not defined in process.env");
}
function optionallyFromEnv(key) {
    // tslint:disable-next-line:no-process-env
    return process.env[key];
}
var ApiEnv = /** @class */ (function () {
    function ApiEnv() {
    }
    ApiEnv.prototype.getPublicApiKey = function () {
        return fromEnv('stripePublicKey');
    };
    ApiEnv.prototype.getPrivateStripApiKey = function () {
        return fromEnv('stripeSecretKey');
    };
    ApiEnv.prototype.getApiProductId = function () {
        return fromEnv('stripeApiProduct');
    };
    ApiEnv.prototype.getProProductId = function () {
        return fromEnv('stripeProProduct');
    };
    ApiEnv.prototype.getWebHookSharedSecret = function () {
        return fromEnv('stripeWebhookSecret');
    };
    ApiEnv.prototype.s3Bucket = function () {
        return fromEnv('apiAppIconS3Bucket');
    };
    ApiEnv.prototype.s3BaseKey = function () {
        return fromEnv('apiAppIconS3KeyPrefix');
    };
    ApiEnv.prototype.appIconPublicUrlBase = function () {
        return fromEnv('apiAppIconPublicUrlBase');
    };
    ApiEnv.prototype.appIconTokenKey = function () {
        return '78bf342cbaa9d43658e0a8f0f069a92e';
    };
    return ApiEnv;
}());
exports.ApiEnv = ApiEnv;
var WebsiteEnv = /** @class */ (function () {
    function WebsiteEnv() {
    }
    WebsiteEnv.prototype.stage = function () {
        return fromEnv('stage');
    };
    WebsiteEnv.prototype.isDev = function () {
        return this.stage() === 'dev';
    };
    WebsiteEnv.prototype.isTest = function () {
        return this.stage() === 'test';
    };
    WebsiteEnv.prototype.isStaging = function () {
        return this.stage() === 'staging';
    };
    WebsiteEnv.prototype.isProduction = function () {
        return this.stage() === 'production';
    };
    WebsiteEnv.prototype.staticSrcPath = function () {
        return fromEnv('staticSrcPath');
    };
    WebsiteEnv.prototype.viewerDeployPath = function () {
        return fromEnv('viewerDeployPath');
    };
    WebsiteEnv.prototype.viewerAssetPath = function () {
        if (this.isDev()) {
            return window.location.protocol + "//" + window.location.hostname + ":8080";
        }
        var match = preconditions_ts_1.required(this.viewerDeployPath().match(/^(.*\/).*\.js$/));
        return preconditions_ts_1.required(match[1]);
    };
    WebsiteEnv.prototype.cookieSecret = function () {
        return fromEnv('cookieSecret');
    };
    WebsiteEnv.prototype.dynamicConfigBucket = function () {
        return optionallyFromEnv('dynamicConfigBucket');
    };
    WebsiteEnv.prototype.dynamicConfigKey = function () {
        return optionallyFromEnv('dynamicConfigKey');
    };
    WebsiteEnv.prototype.serverHost = function () {
        return fromEnv('serverHost');
    };
    WebsiteEnv.prototype.getUseRollbarNotifier = function () {
        return optionallyFromEnv('rollbarAccessToken') !== undefined;
    };
    WebsiteEnv.prototype.getRollbarAccessToken = function () {
        return fromEnv('rollbarAccessToken');
    };
    WebsiteEnv.prototype.getRollbarCodeVersion = function () {
        return fromEnv('rollbarCodeVersion');
    };
    WebsiteEnv.prototype.getRollbarEnvironment = function () {
        return fromEnv('rollbarEnv');
    };
    WebsiteEnv.prototype.getCookieMaxAge = function () {
        // TODO: this can actually go into a config file at some point
        // I don't suppose it needs to change often, so I'm ok leaving
        // it here for now.
        return (100 * 24 * 60 * 60).toString(); // 100 days in seconds
    };
    WebsiteEnv.prototype.getSetCookieSecure = function () {
        return !this.isDev();
    };
    WebsiteEnv.prototype.getGitHash = function () {
        return fromEnv('GIT_HASH');
    };
    WebsiteEnv.prototype.getGoogleAnalyticsKey = function () {
        return fromEnv('googleAnalyticsKey');
    };
    WebsiteEnv.prototype.getGoogleVerificationToken = function () {
        return fromEnv('googleVerificationToken');
    };
    WebsiteEnv.prototype.getFacebookAppId = function () {
        return parseInt(fromEnv('facebookAppId'), 10);
    };
    WebsiteEnv.prototype.getAnalyticsEndpoint = function () {
        return fromEnv('ANALYTICS_ENDPOINT');
    };
    WebsiteEnv.prototype.getEncodedPath = function () {
        return fromEnv('encodedPath');
    };
    WebsiteEnv.prototype.getHubspotScriptUrl = function () {
        return fromEnv('hubspotScriptUrl');
    };
    WebsiteEnv.prototype.getStravaIntegrationHost = function () {
        return fromEnv('stravaIntegrationHost');
    };
    WebsiteEnv.prototype.getStravaIntegrationSharedSecret = function () {
        return fromEnv('stravaIntegrationSharedSecret');
    };
    WebsiteEnv.prototype.getStravaServiceCredentials = function () {
        return fromEnv('stravaServiceCredentials');
    };
    WebsiteEnv.prototype.getSceneCardServiceEndpoint = function () {
        return fromEnv('sceneCardServiceEndpoint');
    };
    WebsiteEnv.prototype.getSceneCardServiceCredentials = function () {
        return fromEnv('sceneCardServiceCredentials');
    };
    return WebsiteEnv;
}());
exports.WebsiteEnv = WebsiteEnv;
var StudioEnv = /** @class */ (function () {
    function StudioEnv() {
    }
    StudioEnv.prototype.mediaBucket = function () {
        return fromEnv('studioMediaBucket');
    };
    StudioEnv.prototype.mediaKeyPrefix = function () {
        return fromEnv('studioMediaS3KeyPrefix');
    };
    StudioEnv.prototype.mediaPublicUrlBase = function () {
        return fromEnv('studioMediaPublicUrlBase');
    };
    StudioEnv.prototype.mediaTokenKey = function () {
        return 'a75c440644214f028a9a7d418dea4063';
    };
    StudioEnv.prototype.localMediaServer = function () {
        return optionallyFromEnv('studioLocalMediaServer');
    };
    StudioEnv.prototype.getMobileJwtSecret = function () {
        return fromEnv('mobileJwtSecret');
    };
    return StudioEnv;
}());
exports.StudioEnv = StudioEnv;
var FeaturesEnv = /** @class */ (function () {
    function FeaturesEnv() {
    }
    FeaturesEnv.prototype.hashSeed = function () {
        return parseInt(fromEnv('featuresHashSeed', 'no hash seed found for feature matrix'), 10);
    };
    FeaturesEnv.prototype.bucket = function () {
        return fromEnv('featuresBucket');
    };
    FeaturesEnv.prototype.cacheLength = function () {
        return parseInt(optionallyFromEnv('featuresCacheLength') || '300', 10);
    };
    return FeaturesEnv;
}());
var FirebaseEnv = /** @class */ (function () {
    function FirebaseEnv() {
    }
    FirebaseEnv.prototype.apiKey = function () {
        return fromEnv('firebaseApiKey');
    };
    FirebaseEnv.prototype.authDomain = function () {
        return fromEnv('firebaseAuthDomain');
    };
    FirebaseEnv.prototype.projectId = function () {
        return fromEnv('firebaseProjectId');
    };
    FirebaseEnv.prototype.storageBucket = function () {
        return fromEnv('firebaseStorageBucket');
    };
    FirebaseEnv.prototype.messagingSenderId = function () {
        return fromEnv('firebaseMessagingSenderId');
    };
    return FirebaseEnv;
}());
exports.env = fn_1.memoize(function () { return new WebsiteEnv(); });
exports.apiEnv = fn_1.memoize(function () { return new ApiEnv(); });
exports.studioEnv = fn_1.memoize(function () { return new StudioEnv(); });
exports.featuresEnv = fn_1.memoize(function () { return new FeaturesEnv(); });
exports.firebaseEnv = fn_1.memoize(function () { return new FirebaseEnv(); });
exports.searchEnv = fn_1.memoize(function () { return ({
    endpoint: preconditions_ts_1.required(optionallyFromEnv('CI_ES_ENDPOINT') || fromEnv('ES_ENDPOINT'), 'no search endpoint found in env config'),
    defaultNearDistanceKm: 100,
    defaultPageSize: 30,
    playAggregationPeriod: 1000 * 60 * 60,
}); });
