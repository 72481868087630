"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var footer_1 = require("../../clean-components/footer");
var header_1 = require("../../clean-components/header");
var videoHero_1 = require("../../components/videoHero");
var env_1 = require("../../helpers/env");
require("../../pages/global.css");
// tslint:disable-next-line:no-var-requires
var styles = require('./home.css');
exports.homeJSX = function (store, loginState, features) {
    // setup the actions
    return (React.createElement(react_redux_1.Provider, { store: store },
        React.createElement("div", null,
            React.createElement(header_1.Header, { staticSrcPath: env_1.env().staticSrcPath(), loginState: loginState }),
            React.createElement("div", null,
                React.createElement(videoHero_1.Hero, { backgroundImg: env_1.env().staticSrcPath() + "/assets/images/backgrounds/Ground_2.png", videoId: 'LGpSyerGjmU' },
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.tagline },
                            "Your view to the world's great",
                            React.createElement("span", null, "adventures ")))),
                React.createElement("div", { className: styles.howItWorks },
                    React.createElement("h1", null, "You track your adventure, we bring back the experience"),
                    React.createElement("div", { className: styles.steps },
                        React.createElement("div", null,
                            React.createElement("img", { src: env_1.env().staticSrcPath() + '/assets/images/illustrations/Activity.png' }),
                            React.createElement("h3", null, "Record your activity")),
                        React.createElement("div", null,
                            React.createElement("img", { src: env_1.env().staticSrcPath() + '/assets/images/illustrations/GPS_262.png' }),
                            React.createElement("h3", null, "Upload Your Track")),
                        React.createElement("div", null,
                            React.createElement("img", { src: env_1.env().staticSrcPath() + '/assets/images/illustrations/3D_viewer.png' }),
                            React.createElement("h3", null, "Watch your 3d Scene"))))),
            React.createElement(footer_1.Footer, null))));
};
